import React, { useState } from 'react';
import ToggleMenuButton from '../../components/ToggleMenuButton/ToggleMenuButton';
import Cursor from '../../components/Cursor/Cursor';

import './Skills.scss';
import iconJS from '../../images/skills/javascript.png';
import iconReact from '../../images/skills/react.png';
import iconRedux from '../../images/skills/redux.png';
import iconHTML from '../../images/skills/html-5.png';
import iconCSS from '../../images/skills/css3.png';
import iconSASS from '../../images/skills/sass.png';
import iconB from '../../images/skills/bootstrap.png';
import iconAnt from '../../images/skills/ant-design.png';
import responsive from '../../images/skills/responsive.png';
import iconvue from '../../images/skills/vue-js.png';
import iconQuasar from '../../images/skills/quasar.png';
import iconGraphQl from '../../images/skills/graphQl.png';
import iconTS from '../../images/skills/typescript.png';
import iconCypress from '../../images/skills/cypress.png';
import iconDetox from '../../images/skills/detox.png';

const navItems = [
  { name: 'home', path: '/', exact: true },
  { name: 'about me', path: '/about' },
  { name: 'projects', path: '/projects' },
  { name: 'contact', path: '/contact' },
];

const skills = [
  { name: 'HTML5', src: iconHTML },
  { name: 'CSS3', src: iconCSS },
  { name: 'SASS', src: iconSASS },
  { name: 'Bootstrap', src: iconB },
  { name: 'JavaScript', src: iconJS },
  { name: 'TypeScript', src: iconTS },
  { name: 'React', src: iconReact },
  { name: 'React Native', src: iconReact },
  { name: 'Vue.js', src: iconvue },
  { name: 'Quasar', src: iconQuasar },
  { name: 'RWD', src: responsive },
  { name: 'Redux', src: iconRedux },
  { name: 'Ant-design', src: iconAnt },
  { name: 'GraphQL', src: iconGraphQl },
  { name: 'Cypress', src: iconCypress },
  { name: 'Detox', src: iconDetox },
];

const Skills = () => {
  const [cursorPointer, setCursorPointer] = useState(false);

  const skillsList = skills.map((item) => (
    <div className="skill" key={item.name}>
      <img src={item.src} alt="icon"></img>
      <p>{item.name}</p>
    </div>
  ));

  return (
    <>
      <Cursor
        cursorClass={
          cursorPointer ? 'navy-color-cursor active' : 'navy-color-cursor'
        }
      />
      <div
        onMouseEnter={() => setCursorPointer(true)}
        onMouseLeave={() => setCursorPointer(false)}
      >
        <ToggleMenuButton buttonColor={'#2c446a'} navItems={navItems} />
      </div>
      <div className="skills-background">
        <div className="skills-section">
          <div className="skills-section__skills">
            <h1>
              skills<span>:</span>
            </h1>

            <div>{skillsList}</div>
          </div>
          <div className="skills-footer">
            <p>
              skills icons by{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://icons8.com"
              >
                Icons8
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Skills;
