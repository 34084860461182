import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Navigation.scss";
import Cursor from "../../components/Cursor/Cursor";

const navItems = [
  { name: "about me", path: "/about", exact: true },
  { name: "projects", path: "/projects" },
  { name: "skills", path: "/skills" },
  { name: "contact", path: "/contact" }
];

const Navigation = () => {
  const [cursorPointer, setCursorPointer] = useState(false);

  const menu = navItems.map(item => (
    <NavLink
      onMouseEnter={() => setCursorPointer(true)}
      onMouseLeave={() => setCursorPointer(false)}
      key={item.name}
      to={item.path}
      exact={item.exact ? item.exact : false}
    >
      {item.name}
    </NavLink>
  ));

  return (
    <>
      <Cursor cursorClass={cursorPointer? 'active': null}/>
      <div className="navigation">
        <nav className="navigation__menu ">
          <div>{menu}</div>
        </nav>
      </div>
    </>
  );
};

export default Navigation;
