import React from "react";
import "./Project.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const Project = (props) => {
  const listItems = props.techniques.map((item) => <li>{item}</li>);

  return (
    <div className={`project ${props.className}`}>
      <figure>
        <div className="project__img">
          <img src={props.src} alt="" />
        </div>
        <figcaption>
          <h2>{props.name}</h2>
          <p>{props.info}</p>
          <div className="techniques">
            <ul>
              <span>
                <FontAwesomeIcon icon={faChevronLeft} />
              </span>
              {listItems}
              <span>
                /<FontAwesomeIcon icon={faChevronRight} />
              </span>
            </ul>
          </div>
          <div className="buttons">
            <a
              className="githubicon"
              href={props.hrefCode}
              target="_blank"
              rel="noopener noreferrer"
              onMouseEnter={props.onMouseEnter}
              onMouseLeave={props.onMouseLeave}
            >
              <FontAwesomeIcon icon={faGithub} />
            </a>
            <a
              href={props.hrefLive}
              target="_blank"
              rel="noopener noreferrer"
              onMouseEnter={props.onMouseEnter}
              onMouseLeave={props.onMouseLeave}
            >
              <FontAwesomeIcon icon={faPlayCircle} />
            </a>
          </div>
        </figcaption>
      </figure>
    </div>
  );
};

export default Project;
