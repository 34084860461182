import React from "react";
import Navigation from "../../layouts/Navigation/Navigation";
import "./HomePage.scss";
import circle1 from "../../images/circle1.png";
import circle2 from "../../images/circle2.png";
import circle3 from "../../images/circle3.png";
import circle4 from "../../images/circle4.png";
import circle5 from "../../images/circle5.png";
import circle6 from "../../images/circle6.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";

const HomePage = () => {
  return (
    <>
      <div className="animation">
        <p className="animation__welcome">Hi! This is my...</p>
        <p className="animation__text">portfolio</p>
        <p className="animation__signature">
          anna wróbel <br /> <span>front-end developer</span>
        </p>
        <div className="animation__circles">
          <div className="animation__circle1">
            <img src={circle1} alt="circle" />
          </div>
          <div className="animation__circle2">
            <img src={circle2} alt="circle" />
          </div>
          <div className="animation__circle3">
            <img src={circle3} alt="circle" />
          </div>
          <div className="animation__circle4">
            <img src={circle4} alt="circle" />
          </div>
          <div className="animation__circle5">
            <img src={circle5} alt="circle" />
          </div>
          <div className="animation__circle6">
            <img src={circle6} alt="circle" />
          </div>
        </div>
        <Navigation />
        <div className="footer">
          <p>
            Copyright <FontAwesomeIcon icon={faCopyright} /> 2020 Anna Wróbel
          </p>
        </div>
      </div>
    </>
  );
};

export default HomePage;
