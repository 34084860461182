import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ToggleMenuButton from '../../components/ToggleMenuButton/ToggleMenuButton';
import photo from '../../images/photo.jpg';
import circle7 from '../../images/circle7.png';
import './About.scss';
import Cursor from '../../components/Cursor/Cursor';

const navItems = [
  { name: 'home', path: '/', exact: true },
  { name: 'projects', path: '/projects' },
  { name: 'skills', path: '/skills' },
  { name: 'contact', path: '/contact' },
];

const About = () => {
  const [cursorPointer, setCursorPointer] = useState(false);

  return (
    <>
      <Cursor
        cursorClass={
          cursorPointer ? 'coral-color-cursor active' : 'coral-color-cursor'
        }
      />
      <div
        onMouseEnter={() => setCursorPointer(true)}
        onMouseLeave={() => setCursorPointer(false)}
      >
        <ToggleMenuButton buttonColor={'#ea6962'} navItems={navItems} />
      </div>

      <div className="about-section">
        <h1>about me</h1>

        <div className="about-section__content">
          <div>
            <p>
              I am a creative person, so as a frontend developer, I enjoy
              creating beautiful and functional web and mobile applications.
              Additionally, problem-solving brings me satisfaction. The
              technologies I use and constantly develop in are <b>React</b> and
              <b> React Native</b>.
            </p>
            <p>
              I graduated from college with a Master's degree in Pharmacy
              however I finally found passion in coding and design. Except that,
              my heart belongs to traveling, books, coffee and my dog :)
            </p>
            <p>
              Find more about my tech abilities in{' '}
              <Link
                to="/skills"
                onMouseEnter={() => setCursorPointer(true)}
                onMouseLeave={() => setCursorPointer(false)}
              >
                <span>skills</span>
              </Link>{' '}
              section.
            </p>
          </div>
        </div>
        <div className="about-section__picture">
          <img src={photo} alt="" />
          <div className="about-section__circle2">
            <img src={circle7} alt="circle" />
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
