import React from "react";
import { Route, Switch } from 'react-router-dom';

import Navigation from '../Navigation/Navigation'
import HomePage from '../../pages/HomePage/HomePage'
import About from '../../pages/About/About'
import Projects from '../../pages/Projects/Projects'
import Skills from '../../pages/Skills/Skills'
import Contact from '../../pages/Contact/Contact'
import ErrorPage from '../../pages/ErrorPage/ErrorPage'


const Page = () => {
  return (
    <>
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/about" component={About} />
        <Route path="/projects" component={Projects} />
        <Route path="/skills" component={Skills} />
        <Route path="/contact" component={Contact} />
        <Route path="/menu" component={Navigation} />
        <Route component={ErrorPage} />
      </Switch>
    </>
  );
};

export default Page;
