import React, { useState } from 'react';
import ToggleMenuButton from '../../components/ToggleMenuButton/ToggleMenuButton';
import './Contact.scss';
import Cursor from '../../components/Cursor/Cursor';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithubSquare } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons';

const navItems = [
  { name: 'home', path: '/', exact: true },
  { name: 'about me', path: '/about' },
  { name: 'projects', path: '/projects' },
  { name: 'skills', path: '/skills' },
];

const contactItems = [
  {
    name: 'wrobel.anne@outlook.com',
    link: 'mailto:wrobel.anne@outlook.com',
    icon: faEnvelopeSquare,
  },
  {
    name: 'linkedin',
    link: 'https://www.linkedin.com/in/wrobel-anne/',
    icon: faLinkedin,
  },
  {
    name: 'github',
    link: 'https://github.com/Whateverimann',
    icon: faGithubSquare,
  },
];

const Contact = () => {
  const [cursorPointer, setCursorPointer] = useState(false);

  const contacts = contactItems.map((item) => (
    <p key={item.name}>
      <a
        onMouseEnter={() => setCursorPointer(true)}
        onMouseLeave={() => setCursorPointer(false)}
        href={item.link}
        className={item.className}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={item.icon} />
        <span>{item.name}</span>
      </a>
    </p>
  ));

  return (
    <>
      <Cursor
        cursorClass={
          cursorPointer ? 'blue-color-cursor active' : 'blue-color-cursor'
        }
      />
      <div
        onMouseEnter={() => setCursorPointer(true)}
        onMouseLeave={() => setCursorPointer(false)}
      >
        <ToggleMenuButton buttonColor={'#024c79'} navItems={navItems} />
      </div>
      <div className="contact-section">
        <div className="contact-section__contacts">
          <h1>
            contact<span>:</span>
          </h1>

          <div>{contacts}</div>
        </div>
      </div>
    </>
  );
};

export default Contact;
