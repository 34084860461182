import React from "react";
import { HashRouter } from "react-router-dom";
import Page from "../Page/Page";

const App = () => {

  return (
    <HashRouter>
      <Page />
    </HashRouter>
  );
}

export default App;
