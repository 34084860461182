import React, { useState } from 'react';
import ToggleMenuButton from '../../components/ToggleMenuButton/ToggleMenuButton';
import Cursor from '../../components/Cursor/Cursor';
import Project from '../../components/Project/Project';
import coffeShop from '../../images/projects/coffeshop.jpg';
import portfolio from '../../images/projects/portfolio.jpg';
import chemat from '../../images/projects/chemat.png';
import taskBoard from '../../images/projects/task-board.png';
import './Projects.scss';

const navItems = [
  { name: 'home', path: '/', exact: true },
  { name: 'about me', path: '/about' },
  { name: 'skills', path: '/skills' },
  { name: 'contact', path: '/contact' },
];

const techniquesCoffeShop = [
  'HTML',
  'CSS',
  'JavaScript',
  'React',
  'Redux',
  'Inpost API',
  'Ant-design',
  'RWD',
];
const techniquesPortfolio = [
  'HTML',
  'SASS',
  'JavaScript',
  'React',
  'React-Router',
  'Figma',
  'RWD',
];

const techniquesChemat = [
  'HTML',
  'CSS',
  'JavaScript',
  'jQuery',
  'Bootstrap',
  'Figma',
];

const techniquesTaskBoard = ['HTML', 'CSS', 'JavaScript', 'Vue.js'];

const products = [
  {
    name: 'coffee shop cart',
    info: 'Simple shop cart simulator.',
    src: coffeShop,
    hrefLive: 'https://whateverimann.github.io/CoffeeShopCart/',
    hrefCode: 'https://github.com/Whateverimann/CoffeeShopCart',
    techniques: [...techniquesCoffeShop],
  },
  {
    name: 'portfolio',
    info: 'My portfolio, hope you like it.',
    src: portfolio,
    hrefLive: 'http://whateverimann.pl/',
    hrefCode: 'https://github.com/Whateverimann/PORTFOLIO',
    techniques: [...techniquesPortfolio],
    className: 'no-code',
  },
  {
    name: 'chemat website',
    info: 'Website for Chemat company.',
    src: chemat,
    hrefLive: 'http://chemat.com.pl/',

    techniques: [...techniquesChemat],
    className: 'no-code',
  },
  {
    name: 'task board',
    info: 'Simple task board built with Vue.js.',
    src: taskBoard,
    hrefLive: 'https://whateverimann.github.io/TaskBoardVue/',
    hrefCode:
      'https://github.com/Whateverimann/TaskBoardVue/tree/master/VueTaskBoard',
    techniques: [...techniquesTaskBoard],
  },
];

const Projects = () => {
  const [cursorPointer, setCursorPointer] = useState(false);

  const projects = products.map((item) => (
    <Project
      key={item.name}
      name={item.name}
      info={item.info}
      src={item.src}
      techniques={item.techniques}
      hrefLive={item.hrefLive}
      hrefCode={item.hrefCode}
      className={item.className}
      onMouseEnter={() => setCursorPointer(true)}
      onMouseLeave={() => setCursorPointer(false)}
    />
  ));

  return (
    <>
      <Cursor
        cursorClass={
          cursorPointer ? 'coral-color-cursor active' : 'coral-color-cursor'
        }
      />
      <div
        onMouseEnter={() => setCursorPointer(true)}
        onMouseLeave={() => setCursorPointer(false)}
      >
        <ToggleMenuButton buttonColor={'#ea6962'} navItems={navItems} />
      </div>

      <div className="projects-section">{projects}</div>
    </>
  );
};

export default Projects;
