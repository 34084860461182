import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Cursor from "../Cursor/Cursor";
import './ToggleNavigation.scss'

const ToggleNavigation = (props) => {
  const [cursorPointer, setCursorPointer] = useState(false);

  const menu = props.navItems.map(item => (
    <NavLink
      onMouseEnter={() => setCursorPointer(true)}
      onMouseLeave={() => setCursorPointer(false)}
      key={item.name}
      to={item.path}
      exact={item.exact ? item.exact : false}
    >
      {item.name}
    </NavLink>
  ));

  return (
    <>
      <Cursor cursorClass={cursorPointer? 'active': null}/>
      <div className="navigation">
        <nav className="navigation__menu ">
          <div>{menu}</div>
        </nav>
      </div>
    </>
  );
};

export default ToggleNavigation;