import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import ToggleNavigation from "../ToggleNavigation/ToggleNavigation";
import "./ToggleMenuButton.scss";

const ToggleMenuButton = (props) => {
  const [hovered, setHovered] = useState(false);

  const handleHover = () => {
    setHovered(!hovered);
  };
  const handleClick = () => {
    setHovered(!hovered);
  };

  return (
    <>
      <button onClick={handleClick} className={"toggle-button"}>
        {hovered ? (
          <FontAwesomeIcon
            icon={faTimesCircle}
            style={{ color: "#fff" }}
            className={"toggle-button__icon"}
          />
        ) : (
          <FontAwesomeIcon
            icon={faEllipsisH}
            style={{ color: props.buttonColor }}
            onMouseEnter={handleHover}
          />
        )}
      </button>
      <div
        className={hovered ? "toggle-menu" : "hidden toggle-menu"}
        style={{ backgroundColor: props.buttonColor }}
        onMouseLeave={handleHover}
      >
        <ToggleNavigation navItems={props.navItems} />
      </div>
    </>
  );
};

export default ToggleMenuButton;
