import React from "react";
import "./ErrorPage.scss"

const ErrorPage = () => {
  return (
    <div className="error">
      <h1>Ouuups! You are lost!</h1>
    </div>
  );
};

export default ErrorPage;
